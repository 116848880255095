import {RouterPrefix} from '@wix/wixstores-client-core/dist/es/src/constants';
import {LocaleKeys} from '../../../__generated__/LocaleKeys';
import {SPECS} from '../../../editor-script/constants';
import {getCategoryPageStatusForSite} from '../categories-rollout-manager/categoriesRolloutManagerUtils';
import {EditorType} from '@wix/editor-platform-sdk-types';

export async function getCategoryPageSiteStatus(ecomPublicApi, experimentsApi, options) {
  if (experimentsApi.enabled(SPECS.UseCategoriesRolloutManager)) {
    const isCategorySiteRes = await getCategoryPageStatusForSite(
      options.initialAppData.metaSiteId,
      options.essentials.httpClient,
      ecomPublicApi.getInstance()
    );
    return isCategorySiteRes.data.result.value;
  }
}

export function shouldInstallCategoryPage(
  editorType: EditorType,
  currentSiteCategoryPageState: boolean | null,
  categoryPageExperimentEnabled: boolean
): boolean {
  const validEditor = editorType === EditorType.Classic;
  const siteHadCategoryPageBefore = currentSiteCategoryPageState === true;
  const shouldInstallCategoryPageForTheFirstTime = currentSiteCategoryPageState === null;
  if (!validEditor) {
    return false;
  }
  return siteHadCategoryPageBefore || (categoryPageExperimentEnabled && shouldInstallCategoryPageForTheFirstTime);
}

export async function createCategoryPageRouter(ecomPublicApi, categoryPageRef) {
  const routerRef = await ecomPublicApi.addRouter(RouterPrefix.CATEGORY);
  const router = await ecomPublicApi.getRouter(routerRef);
  await ecomPublicApi.connectPageToRouter({
    pageRef: categoryPageRef,
    routerRef,
    pageRoles: ['category'],
  });

  return router;
}

export function addAllProductsCategoryToDefaultSiteMenu(editorSdk, t, router, categoryPageRef) {
  return editorSdk.menu.addItem('', {
    menuId: 'CUSTOM_MAIN_MENU',
    menuItem: {
      type: 'BasicMenuItem',
      isVisible: true,
      label: t(LocaleKeys().siteMenu.store.defaultPage.name),
      link: {
        type: 'DynamicPageLink',
        routerId: router.id,
        pageId: categoryPageRef.id,
        innerRoute: 'all-products',
      },
    },
  });
}
